import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SlickPrevArrowTwo from './SlickPrevArrowTwo';
import SlickNextArrowTwo from './SlickNextArrowTwo';
import './TestimonialsCarousel.css';
import QuoteLeft from './img/quote_left.png';

const TestimonialsCarousel = () => {
  const { t } = useTranslation();

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    initialSlide: 1,
    prevArrow: <SlickPrevArrowTwo />,
    nextArrow: <SlickNextArrowTwo />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section>
      <Slider {...settings} className="sliderTagsContainer">
        <article className="sliderTagBox">
          <div>
            <img src={QuoteLeft} alt="Quote Shapya" />
            <p className="tagTextParag">
              {t('indexbody.testimonials.card01Description')}
            </p>
            <p className="tagTextName">Karla Brantner</p>
            <p className="tagTextCat">El Mandado, Raleigh NC</p>
          </div>
        </article>

        <article className="sliderTagBox">
          <div>
            <img src={QuoteLeft} alt="Quote Shapya" />
            <p className="tagTextParag">
              {t('indexbody.testimonials.card02Description')}
            </p>
            <p className="tagTextName">Adali Aguilera</p>
            <p className="tagTextCat">El Moroleon, Durham, NC</p>
          </div>
        </article>

        <article className="sliderTagBox">
          <div>
            <img src={QuoteLeft} alt="Quote Shapya" />
            <p className="tagTextParag">
              {t('indexbody.testimonials.card03Description')}
            </p>
            <p className="tagTextName">Miguel Villaseñor</p>
            <p className="tagTextCat">Los Portales, Wilmington NC</p>
          </div>
        </article>
      </Slider>
    </section>
  );
};

export default TestimonialsCarousel;
