import '../i18n';

import AboutUsBody from '../components/aboutUs/AboutUsBody';
import { BackToTop } from 'material-ui-back-to-top';
import Footer from '../components/Footer';
import NavbarWhite from '../components/navbar/NavbarWhite';
import React from 'react';
import SEO from '../components/seo';
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <SEO
        title={t('seo.page.aboutUs.title')}
        description={t('seo.page.aboutUs.description')}
        keywords={[
          'shapya',
          'About Us',
          'shopping',
          'delivering',
          'groceries',
          'bakery',
          'meals',
          'meet our stores',
          'food truck',
          'stores',
          'malls',
          'restaurants',
          'raleigh nc',
          'latin stores in raleigh nc',
          'Interfood Plaza Latina',
          'Moroleon Supermarket',
          'El Mandado Supermarket',
          'Dulcería el Castillo',
          'Candy Fiesta & Artesanías',
          'Carnicería La Superior',
          'Raleigh Meat Market',
          'La Bodega Wholesale Food',
          'El Rey Minisuper',
          'Interfood 3 Hermanos',
          'La Castellana',
        ]}
      />
      <BackToTop size="small" buttonPosition={{ bottom: 20, right: 20 }} />
      <NavbarWhite />
      <AboutUsBody />
      <Footer />
    </React.Fragment>
  );
};

export default AboutUs;
