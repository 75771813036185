import React from 'react';
import ChevronLeftSharpIcon from '@material-ui/icons/ChevronLeftSharp';
import './TestimonialsCarousel.css';

const SlickPrevArrowTwo = (props) => {
  const { onClick } = props;

  return (
      <ChevronLeftSharpIcon
          className="prevSlide"
          onClick={onClick}
      />
  );
}

export default SlickPrevArrowTwo;