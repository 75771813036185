import '../styles/shapyaMaxWidth.css';
import './aboutUsStyles.css';

import { Box, Grid } from '@material-ui/core';

import AboutUsHead from './aboutUsHead/AboutUsHead';
import AboutUsHowWeContribute from './aboutUsHowWeContribute/AboutUsHowWeContribute';
import AboutUsStartOrderingNow from './aboutUsStartOrderingNow/AboutUsStartOrderingNow';
import AboutUsWhoWeAre from './aboutUsWhoWeAre/AboutUsWhoWeAre';
import React from 'react';
import Testimonials from './testimonials/Testimonials';
import WhoWeHelp from './whoWeHelp/WhoWeHelp';
import { useTranslation } from 'react-i18next';

const AboutUsBody = () => {
  const { t } = useTranslation();

  return (
    <div className="shapyaMaxWidth">
      <Grid>
        <AboutUsHead />
        <AboutUsWhoWeAre />
        <AboutUsHowWeContribute />
        <WhoWeHelp />
        <Testimonials />
        <AboutUsStartOrderingNow />
      </Grid>
    </div>
  );
};

export default AboutUsBody;
