import React from 'react';
import { useTranslation } from 'react-i18next';
import './aboutUsHowWeContribute.css';

const AboutUsHowWeContribute = () => {
  const { t } = useTranslation();

  return (
    <div className="aboutUsHowWeContributeDiv">
      <p className="aboutUsHowWeContributeTitleDiv">
        {t('aboutus.howWeContribute.title')}
      </p>
      <div className="aboutUsHowWeContributeContentDiv">
        <div className="aboutUsHowWeContributeContent01">
          {t('aboutus.howWeContribute.contentA')}
        </div>
        <div className="aboutUsHowWeContributeContent02">
          {t('aboutus.howWeContribute.contentB')}
        </div>
        <div className="aboutUsHowWeContributeContent03">
          {t('aboutus.howWeContribute.contentC')}
        </div>
      </div>
    </div>
  );
};

export default AboutUsHowWeContribute;
