import '../../styles/shapyaMaxWidth.css';
import './Testimonials.css';
import './TestimonialsCarousel.css';

import React from 'react';
import TestimonialsCarousel from './TestimonialsCarousel';
import { useTranslation } from 'react-i18next';

const Testimonials = () => {

  const { t } = useTranslation();

  return (
    <section className="shapyaMaxWidth testimonialsContainer">
      <section className="clientsSayContainer">
        <div className="testimonialsLeft">
          <h3>{t('aboutus.testimonials.title.1')}</h3>
          <h2>{t('aboutus.testimonials.clientssay.title')}</h2>
          <p>{t('aboutus.testimonials.clients.say.content')}</p>
        </div>
        <div className="testimonialsRight">
          <div className="tagsWrapper">
            <article className="tagText">
              <h2>{t('aboutus.testimonials.tag.1.title')}</h2>
              <p>{t('aboutus.testimonials.tag.1.content')}</p>
            </article>
            <article className="tagText">
              <h2>{t('aboutus.testimonials.tag.2.title')}</h2>
              <p>{t('aboutus.testimonials.tag.2.content')}</p>
            </article>
            <article className="tagText">
              <h2>{t('aboutus.testimonials.tag.3.title')}</h2>
              <p>{t('aboutus.testimonials.tag.3.content')}</p>
            </article>
          </div>
        </div>
      </section>

      <section className="carouselContainer">
        <TestimonialsCarousel />
      </section>
    </section>
  )
};

export default Testimonials;