import '../../styles/shapyaMaxWidth.css';
import './WhoWeHelp.css';

import { Box, DialogContentText, Grid } from '@material-ui/core';

import TagRestaurants from './img/circle_tag_restaurants.png';
import TagShapyers from './img/circle_tag_shapyers.png';
import TagStores from './img/circle_tag_stores.png';
import react from 'react';
import { useTranslation } from 'react-i18next';

const WhoWeHelp = () => {
  const { t } = useTranslation();

  return (
    <section className="shapyaMaxWidth whoWeHelpContainer">
      <div className="boxMainContent">
        <div className="boxLeftTitle">
          <h1>{t('aboutus.whowehelp.title.1')}</h1>
          <h1>{t('aboutus.whowehelp.title.2')}</h1>
        </div>

        <section className="weHelpTags" id="box-1">
          <div className="topCircleImgTags">
            <img
              src={TagStores}
              alt={t('aboutus.whowehelp.tags.img.alt')}
              className="weHelpTagsImg"
            />
          </div>
          <div className="tagBodyContent">
            <h3>{t('aboutus.whowehelp.tag.title.stores')}</h3>
            <p>{t('aboutus.whowehelp.tag.content.stores')}</p>
          </div>
        </section>

        <section className="weHelpTags" id="box-2">
          <div className="topCircleImgTags">
            <img
              src={TagRestaurants}
              alt={t('aboutus.whowehelp.tags.img.alt')}
              className="weHelpTagsImg"
            />
          </div>
          <div className="tagBodyContent">
            <h3>{t('aboutus.whowehelp.tag.title.restaurants')}</h3>
            <p>{t('aboutus.whowehelp.tag.content.restaurants')}</p>
          </div>
        </section>

        <section className="weHelpTags" id="box-3">
          <div className="topCircleImgTags">
            <img
              src={TagShapyers}
              alt={t('aboutus.whowehelp.tags.img.alt')}
              className="weHelpTagsImg"
            />
          </div>
          <div className="tagBodyContent">
            <h3>{t('aboutus.whowehelp.tag.title.shapyers')}</h3>
            <p>{t('aboutus.whowehelp.tag.content.shapyers')}</p>
          </div>
        </section>

      </div>
    </section>
  );
};

export default WhoWeHelp;
