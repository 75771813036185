import React from 'react';
import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp';
import './TestimonialsCarousel.css';

const SlickNextArrowTwo = (props) => {
  const { onClick } = props;

  return (
      <ChevronRightSharpIcon
          className="nextSlide"
          onClick={onClick}
      />
  );
}

export default SlickNextArrowTwo;