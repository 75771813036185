import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import './aboutUsWhoWeAre.css';

const AboutUsWhoWeAre = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="aboutUsWhoWeAreGrid"
      >
        <div className="imgBakery01"></div>
        <div className="imgBakery02"></div>
        <div className="whoWeAreDiv">
          <div className="whoWeAreCard">
            <p className="whoWeAreCardTitle1">{t('aboutus.whoWeAre.who')}</p>
            <p className="whoWeAreCardTitle2">{t('aboutus.whoWeAre.weAre')}</p>
            <p className="whoWeAreCardContent">
              {t('aboutus.whoWeAre.content')}
            </p>
          </div>
        </div>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="aboutUsWhoWeAreSmImgGrid"
      >
        <div className="imgBakery01sm"></div>
        <div className="imgBakery02sm"></div>
      </Grid>
    </div>
  );
};

export default AboutUsWhoWeAre;
