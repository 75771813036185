import React from 'react';
import { useTranslation } from 'react-i18next';
import './aboutUsHead.css';

const AboutUsHead = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="aboutUsHeadDiv">
        <p className="aboutUsHeadTitle">{t('aboutus.title')}</p>
      </div>
    </div>
  );
};

export default AboutUsHead;
