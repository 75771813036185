import React from 'react';
import { useTranslation } from 'react-i18next';
import './aboutUsStartOrderingNow.css';
import Hand from './img/hand.png';
import Googleplay from './img/googleplay.png';
import Appstore from './img/appstore.png';

const AboutUsStartOrderingNow = () => {
  const { t } = useTranslation();

  return (
    <div className="aboutUsStartOrderingNowDiv">
      <div className="aboutUsStartOrderingNowHandSm">
        <img src={Hand} alt="ShapYa Hand Mobile" width="260" heigth="452" />
      </div>
      <div className="aboutUsStartOrderingNowTitle">
        <div>
          <p className="aboutUsStartOrderingNowTitleSm">
            {t('aboutus.startOrderingNow.title')}
          </p>
        </div>
        <div className="aboutUsStartOrderingNowGoogleplayAppstore">
          <a
            href="https://play.google.com/store/apps/details?id=com.shapya.app"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={Googleplay}
              alt="ShapYa Google Play"
              className="aboutUsStartOrderingNowGoogleplay"
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/id1522176109"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={Appstore}
              alt="ShapYa App Store"
              className="aboutUsStartOrderingNowAppstore"
            />
          </a>
        </div>
      </div>
      <div className="aboutUsStartOrderingNowHand">
        <img src={Hand} alt="ShapYa Hand Mobile" />
      </div>
    </div>
  );
};

export default AboutUsStartOrderingNow;
